window.addEventListener("load", function(){
  const autocomplete_fields = document.querySelectorAll('.autocomplete')

  autocomplete_fields.forEach(field => field.addEventListener('keyup', event => {
    initializeAutocomplete(event)
  }))

  // create one global XHR object
  // so we can abort old requests when a new one is make
  window.autocomplete_xhr = new XMLHttpRequest();
});


function initializeAutocomplete(event) {
  const input = event.target
  const autocomplete_list = input.list
  const autocomplete_collection = autocomplete_list.id
  const min_characters = 0

  if (input.value.length < min_characters) return;


  // abort any pending requests
  window.autocomplete_xhr.abort()


  window.autocomplete_xhr.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200) {
      const response = JSON.parse(this.responseText)[autocomplete_collection]

      autocomplete_list.innerHTML = ""

      response.forEach(function(item) {
        const option = document.createElement('option')
        option.value = item['name']
        autocomplete_list.appendChild(option)
      });
    }
  };


  window.autocomplete_xhr.open("GET", "/api/ingredients?query=" + input.value, true)
  window.autocomplete_xhr.send()
}
