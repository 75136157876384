document.addEventListener('DOMContentLoaded', function() {
  const adapt_search_button = document.querySelector('.adapt-search')

  if (adapt_search_button) {
    adapt_search_button.addEventListener('click', toggleSearchForm)
  }


  function toggleSearchForm(e) {
    const search_form = document.querySelector('.search-form')
    search_form.classList.toggle('hidden')
    this.classList.toggle('hidden')
    e.preventDefault()
  }


})
