document.addEventListener('DOMContentLoaded', function() {
  const dynamic_lists = document.querySelectorAll('.dynamic-list')


  dynamic_lists.forEach(list => list.addEventListener('click', event => {
    event.preventDefault()

    if (event.target.matches('.add_dynamic_fields_link')) {
      addDynamicItem(event.target);
    }

    if (event.target.matches('.remove_dynamic_fields_link')) {
      deleteDynamicItem(event.target);
    }
  }))


  function addDynamicItem(element) {
    const time = new Date().getTime()
    const regexp = new RegExp(element.dataset['id'], 'g')
    const html = element.dataset['fields'].replace(regexp, time)

    element.insertAdjacentHTML('beforebegin', html)
  }

  function deleteDynamicItem(element) {
    element.closest('.fields').querySelector('input[type=hidden]').value = 1
    element.closest('.dynamic-fields').remove()
  }
})
