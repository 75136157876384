const nav_buttons = document.querySelectorAll('.nav-btn');

nav_buttons.forEach(element => element.addEventListener('click', event => {
  // Get the dropdown-menu associated with this nav button (insert the id of your menu)
  const dropDownMenu = document.getElementById('HeaderMenu');

  // Toggle the nav-btn and the dropdown menu
  element.classList.toggle('active');
  dropDownMenu.classList.toggle('active');
}));


const submenu_buttons = document.querySelectorAll('.has-sub');

submenu_buttons.forEach(submenu => submenu.addEventListener('click', event => {
  const current_dropdown = submenu.querySelector('.dropdown-menu')
  const dropdown_menus = document.querySelectorAll('.dropdown-menu')

  // Hide all other dropdown menus
  dropdown_menus.forEach(function(element) {
    if (element == current_dropdown) return;

    element.classList.remove('dropdown-shown')
  })

  // Remove the active selector from the other dropdown toggles
  submenu_buttons.forEach(function(element) {
    if (element == submenu) return;

    element.classList.remove('active')
  })

  // Show/hide the dropdown menu associated with the toggle being clicked
  current_dropdown.classList.toggle('dropdown-shown')

  // Toggle the active selector on the nav-item
  submenu.classList.toggle('active')
}));
