document.addEventListener('DOMContentLoaded', function() {
  const zxcvbn = require('@contentpass/zxcvbn')

  const meter = document.querySelector('#PasswordStrengthMeter')
  const text = document.querySelector('#PasswordStrengthText')
  const password_input = document.querySelector('input[type=password].update-meter')


  if (password_input) {
    password_input.addEventListener('input', updatePasswordStrengthMeter)
  }


  function updatePasswordStrengthMeter() {
    const password = this.value

    if (password !== '') {
      const result = zxcvbn(password, {
        user_inputs: ['rezapp', 'myrezapp'],
        feedback_messages: {
          use_a_few_words: "Verwende ein paar Wörter, vermeide häufige Ausdrücke.",
          no_need_for_mixed_chars: "Keine Symbole, Ziffern oder Großbuchstaben erforderlich.",
          uncommon_words_are_better: "Füge noch ein oder zwei Wörter hinzu. Seltene Wörter sind besser.",
          straight_rows_of_keys_are_easy: "Nebeneinanderliegende Tastenreihen sind leicht zu erraten.",
          short_keyboard_patterns_are_easy: "Kurze Tastaturmuster sind leicht zu erraten.",
          use_longer_keyboard_patterns: "Vermeide Tastaturmuster wie „qwertz”.",
          repeated_chars_are_easy: 'Wiederholungen wie "aaa" sind leicht zu erraten.',
          repeated_patterns_are_easy: "Wiederholungen wie „abcabcabcabc” sind nur etwas schwerer zu erraten als „abc”.",
          avoid_repeated_chars: "Vermeide wiederholte Wörter und Zeichen.",
          sequences_are_easy: "Sequenzen wie abc oder 6543 sind leicht zu erraten.",
          avoid_sequences: "Vermeide Sequenzen.",
          recent_years_are_easy: "Die letzten Jahre sind leicht zu erraten.",
          avoid_recent_years: "Vermeide die letzten Jahre.",
          avoid_associated_years: "Vermeide Jahre, die mit dir verbunden sind.",
          dates_are_easy: "Datumsangaben sind oft leicht zu erraten.",
          avoid_associated_dates_and_years: "Vermeide ein Datum und Jahr, die mit dir in Verbindung stehen.",
          top10_common_password: "Dies ist ein gängiges Top-10-Passwort.",
          top100_common_password: "Dies ist ein gängiges Top-100-Passwort.",
          very_common_password: "Dies ist ein sehr gebräuchliches Passwort.",
          similar_to_common_password: "Dies ist vergleichbar mit einem häufig verwendeten Passwort.",
          a_word_is_easy: "Ein Wort für sich allein ist leicht zu erraten.",
          names_are_easy: "Vor- und Nachnamen an sich sind leicht zu erraten.",
          common_names_are_easy: "Gewöhnliche Vor- und Nachnamen sind leicht zu erraten.",
          capitalization_doesnt_help: "Großschreibung hilft nicht viel.",
          all_uppercase_doesnt_help: "Nur Großbuchstaben sind fast so einfach zu erraten wie nur Kleinbuchstaben.",
          reverse_doesnt_help: "Umgekehrte Wörter sind nicht viel schwieriger zu erraten.",
          substitution_doesnt_help: "Vorhersagbare Ersetzungen wie „@” anstelle von „a” helfen nicht viel.",
          user_dictionary: "Dieses Passwort befindet sich auf der Blacklist."
        }
      })
      meter.value = result.score

      let feedback = []
      feedback.push(result.feedback.warning)
      feedback = feedback.concat(result.feedback.suggestions)
      feedback = feedback.filter(function(v) {
        return v !== ''
      })

      return text.innerHTML = feedback.join(' ')
    } else {
      return text.innerHTML = ''
    }
  }
})
