document.addEventListener('DOMContentLoaded', function() {
  const toast = document.querySelector('.toast.toast-floating')
  const close_button = document.querySelector('.toast.toast-floating .btn-close')


  if (toast) {
    toast.addEventListener('animationend', removeToast)
    close_button.addEventListener('click', fadeOutToast)

    setInterval(fadeOutToast, 5000);
  }


  function fadeOutToast() {
    toast.classList.add('fade-out')
  }

  function removeToast() {
    toast.remove()
  }
})
